<!--
 * @Description: 商品详情页面组件
 * @Author: hai-27
 * @Date: 2020-02-16 20:20:26
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 21:59:26
 -->
<template>
	<div class="Supplierinfo" id="supplier">
		<div class="supplierinfo-wrapper index-wrapper boxsiz">
			<div class="si-header">
				<img src="https://static.icdeal.com/images/supplier/banner.jpg" alt="" class="banner i-show">
				<div class="width1225 banner-c boxsiz">
					<div class="top-join-div">
						<div class="supplier-info">
							<p class="title">0 成本拓展商机</p>
							<p class="desc">卡莱集 诚邀您免费入驻</p>
							<a href="#" class="get-yx-btn i-show" target="_blank">了解卡莱集</a>
						</div>
						<div class="supplier-form boxsiz">
							<p class="form-title">入驻申请</p>
							<p class="form-desc">请留下联系方式，您的专属客服将会尽快与您联系</p>
							<p class="account-input clear">
								<span class="fl i-show">+86</span>
								<input type="text" placeholder="请输入手机号码" v-model="name" class="fl boxsiz phone-input">
							</p>
							<p class="account-code clear">
								<input type="text" placeholder="请输入短信验证码" class="fl boxsiz code-input" v-model="yzm">
								<a href="javascript:void(0)" class="btn huoqucode fr" v-if="sendBool" id="phoneLoginMsg"
									@click="sendcode">获取验证码</a>
								<a href="javascript:void(0)" class="btn fr disabled" v-else
									id="phoneLoginMsg">{{sendtTime}} &nbsp; 重新获取</a>
							</p>
							<a href="javascript:void(0)" class="btn now-join-btn" @click="ruzhu">立即入驻</a>
						</div>
					</div>
					<!-- <div class="bottom-contact clear">
						<div class="fl">
							<span class="line-block va-m">温先生</span>
							<a href="javascript:void(0)" class="line-block copy-phone"> <span class="line-block va-m">134 2821
									2392</span></a>
							<a href="https://wpa.qq.com/msgrd?v=3&amp;uin=2881132360&amp;site=qq&amp;menu=yes"
								target="_blank" class="line-block"><i class="line-block q-i va-m"></i><span
									class="line-block va-m">2881132360</span></a>
						</div>
						<div class="fl">
							<span class="line-block va-m">喻女士</span>
							<a href="javascript:void(0)" class="line-block copy-phone"><i
									class="line-block p-i va-m"></i><span class="line-block va-m">137 1400
									5583</span></a>
							<a href="https://wpa.qq.com/msgrd?v=3&amp;uin=2851009312&amp;site=qq&amp;menu=yes"
								target="_blank" class="line-block"><i class="line-block q-i va-m"></i><span
									class="line-block va-m">2851009312</span></a>
						</div>
					</div> -->
				</div>
			</div>
			<div class="si-sct1 width1225">
				<h3>四大优势 合作共赢</h3>
				<ul class="itms clear">
					<li class="itm boxsiz fl">
						<div class="img-div">
							<div class="banner2"></div>
						</div>
						<div class="desc">
							<p class="tit">庞大的客户群体</p>
							<p class="txt">依托于勤基集团成熟的服务能力及客户资源，卡莱集目前拥有5000+大客户，150万+<br>中小微客户，且每日有1200+精准新客户注入。</p>
						</div>
					</li>
					<li class="itm boxsiz fl ml20">
						<div class="img-div">
							<div class="banner3"></div>
						</div>
						<div class="desc">
							<p class="tit">精准的营销手段</p>
							<p class="txt">搜索引擎全渠道推广、新媒体/社群高流量曝光、线下展会多维度品宣，且根据勤基集团积累的电子产业数据库进行大数据分析，迅速响应市场变化，精准触达客户。</p>
						</div>
					</li>
					<li class="itm boxsiz fl">
						<div class="img-div">
							<div class="banner4"></div>
						</div>
						<div class="desc">
							<p class="tit">速增的销售能力</p>
							<p class="txt">日均订单量5000+，一对一专属客服跟单，积极拓展全球市场，为全球客户提供优质服务，近3年，年复合增长率超过415%。</p>
						</div>
					</li>
					<li class="itm boxsiz fl ml20">
						<div class="img-div">
							<div class="banner5"></div>
						</div>
						<div class="desc">
							<p class="tit">高效的仓储服务</p>
							<p class="txt">拥有完善的ERP自研系统，保证仓储高效运作，7天24小时极速交付，为客户和供应商合作伙伴提供高效的一站式服务。</p>
						</div>
					</li>
				</ul>
			</div>

			<div class="si-sct3 boxsiz">
				<div class="width1225 c boxsiz">
					<h3>3步极速入驻</h3>
					<ul class="itms clear">
						<li class="itm fl">
							<img src="https://static.icdeal.com/images/supplier/logo-6.png" alt="">
							<p class="tit"><span class="line-block">1</span>申请入驻</p>
							<p class="txt">填写手机号一键申请</p>
						</li>
						<li class="itm fl">
							<img src="https://static.icdeal.com/images/supplier/logo-7.png" alt="">
							<p class="tit"><span class="line-block">2</span>专人对接</p>
							<p class="txt">专人一对一沟通，签署合作协议</p>
						</li>
						<li class="itm fl">
							<img src="https://static.icdeal.com/images/supplier/logo-8.png" alt="">
							<p class="tit"><span class="line-block">3</span>成功入驻</p>
							<p class="txt">提供库存信息或铺货至卡莱集</p>
						</li>
					</ul>
					<a href="javascript:void(0)" class="i-show now-join-btn1" @click="ruzhu_bottom">立即入驻</a>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				productNum: 1,
				dis: false, // 控制“加入购物车按钮是否可用”
				productID: "", // 商品id
				productDetails: "", // 商品详细信息
				productPicture: "", // 商品图片
				productContent: '',
				TopGoods: [],
				sendtTime: 30,
				sendBool: true,
				name: '',
				yzm: '',
			};
		},
		methods: {
			async sendcode() {
				var that = this
				const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
				if (!regMobile.test(this.name)) {
					this.notifyError('请输入正确的手机号');
					return
				}
				var res = await this.postdata("/api/Login/SendCode", {
					Mobile: this.name,
					SmsType: 0,
				})
				if (res.code == 200) {
					this.notifySucceed('发送成功');
					this.sendBool = false
					var time = setInterval(function() {
						that.sendtTime--
						if (that.sendtTime <= 0) {
							that.sendBool = true
							that.sendtTime = 30
							clearTimeout(time)
						}
					}, 1000)
				} else {
					this.notifyError('发送失败');
				}

			},

			ruzhu() {
				const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
				if (!regMobile.test(this.name)) {
					this.notifyError('请输入正确的手机号');
					return
				}
				
				if (!this.yzm) {
					this.notifyError('请输入验证码');
					return
				}
				this.notifySucceed('申请成功，请耐心等待');
			},
			ruzhu_bottom() {
				console.log($("#supplier"))
				 $("#supplier").animate({scrollTop:0},3000); 
			}
		}
	};
</script>

<style lang="less" scoped>

</style>
<style scoped>
	.supplierinfo-wrapper {
		min-width: 1225px;
		overflow: auto;
		overflow-x: hidden;
		background: #f4f7fd;
		background: linear-gradient(180deg, #fafbff, #f4f7fd 100%);
	}

	.index-wrapper {
		min-height: 100%;
		position: relative;
	}

	.supplierinfo-wrapper .si-header {
		height: 550px;
		position: relative;
	}

	.supplierinfo-wrapper .si-header .banner {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.i-show {
		display: block;
	}

	.supplierinfo-wrapper .si-header .banner-c {
		position: relative;
		padding-top: 80px;
		height: 510px;
	}

	.supplierinfo-wrapper .si-header .top-join-div {
		position: relative;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-info {
		padding: 75px 0 0 20px;
		color: #fff;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-info .title {
		font-size: 40px;
		line-height: 52px;
		font-weight: 600;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-info .desc {
		font-size: 34px;
		line-height: 51px;
		padding-top: 10px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-info .get-yx-btn {
		width: 136px;
		height: 40px;
		background: #00ad42;
		border-radius: 2px;
		line-height: 40px;
		text-align: center;
		color: #f7f7f7;
		font-size: 16px;
		margin-top: 30px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form {
		width: 368px;
		height: 334px;
		background: #FFFFFF;
		border-radius: 6px;
		position: absolute;
		right: 0;
		top: 0;
		padding: 20px 20px 0;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .form-title {
		color: #131313;
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
		padding: 12px 0 0 10px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .form-desc {
		color: #131313;
		font-size: 14px;
		margin-top: 10px;
		padding-left: 10px;
		line-height: 22px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-input {
		font-size: 14px;
		line-height: 39px;
		margin-top: 20px;
		color: #131313;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-input span {
		width: 46px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 2px 0px 0px 2px;
		line-height: 40px;
		text-align: center;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-input input {
		width: 274px;
		border-radius: 0px 2px 2px 0px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form input {
		background: #ffffff;
		border: 1px solid #eeeeee;
		height: 40px;
		line-height: 40px;
		padding: 0 12px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-code {
		margin-top: 12px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-code .huoqucode {
		background-color: #00ad42;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-code input {
		width: 228px;
		border-radius: 2px 0px 0px 2px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form input {
		background: #ffffff;
		border: 1px solid #eeeeee;
		height: 40px;
		line-height: 40px;
		padding: 0 12px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .btn.disabled {
		background: #eeeeee;
		color: #8B8B8B;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .account-code a {
		width: 92px;
		border-radius: 0px 2px 2px 0px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .btn {
		border-radius: 2px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		cursor: pointer;
		display: block;
		font-size: 14px;
	}

	.supplierinfo-wrapper .si-header .top-join-div .supplier-form .now-join-btn {
		width: 320px;
		margin-top: 20px;
		font-size: 16px;
		background-color: #00ad42;
	}

	.supplierinfo-wrapper .si-header .bottom-contact {
		position: absolute;
		bottom: 6px;
		color: #fff;
		font-size: 18px;
		height: 24px;
		line-height: 24px;
		font-size: 0;
		left: 50%;
		transform: translateX(-50%);
		/* padding: 9px 78px; */
	}

	.supplierinfo-wrapper .si-header .bottom-contact div {
		margin-left: 108px;
		font-size: 18px;
	}

	.supplierinfo-wrapper .si-header .bottom-contact div a {
		font-size: 16px;
		color: #fff;
		margin-left: 4px;
	}

	.supplierinfo-wrapper .si-sct1 h3 {
		font-size: 36px;
		line-height: 36px;
		text-align: center;
		color: #000;
		padding: 50px 0 10px;
		font-weight: 600;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm {
		width: 585px;
		height: 356px;
		background: #ffffff;
		border-radius: 10px;
		padding: 20px;
		margin-top: 20px;
		transition: all linear 0.25s;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div {
		width: 545px;
		height: 210px;
		position: relative;
		overflow: hidden;
		border-radius: 6px;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div div {
		position: absolute;
		background: none;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		transition: all linear 0.4s;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div div.banner2 {
		background-image: url(../images/banner2.jpg);
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div div.banner3 {
		background-image: url(../images/banner3.jpg);
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div div.banner4 {
		background-image: url(../images/banner4.jpg);
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .img-div div.banner5 {
		background-image: url(../images/banner5.jpg);
	}


	.supplierinfo-wrapper .si-sct1 .itms .itm .desc {
		padding-top: 10px;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .desc p.tit {
		font-size: 22px;
		font-weight: 600;
		line-height: 44px;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .desc p {
		color: #1B1D23;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm .desc p.txt {
		font-size: 14px;
		line-height: 26px;
	}

	.supplierinfo-wrapper .si-sct1 .itms .itm.ml20 {
		margin-left: 20px;
	}

	.supplierinfo-wrapper .si-sct3 {
		height: 468px;
		padding-top: 50px;
	}

	.supplierinfo-wrapper .si-sct3 .c {
		height: 408px;
		background: #fff;
		box-shadow: 0px 12px 24px rgb(160 179 225 / 10%);
		border-radius: 6px;
		padding-top: 30px;
	}

	.supplierinfo-wrapper .si-sct3 .c h3 {
		font-size: 36px;
		line-height: 70px;
		color: #000;
		text-align: center;
		font-weight: 600;
	}

	.supplierinfo-wrapper .si-sct3 .c .itms .itm {
		width: 396px;
		text-align: center;
	}

	.supplierinfo-wrapper .si-sct3 .c .itms .itm img {
		width: 150px;
		height: 120px;
	}

	.supplierinfo-wrapper .si-sct3 .c .itms .itm .tit {
		font-size: 22px;
		color: #1B1D23;
		font-weight: 600;
		line-height: 44px;
	}

	.supplierinfo-wrapper .si-sct3 .c .itms .itm .tit span {
		width: 24px;
		height: 24px;
		background: #9dd4b2;
		border-radius: 999px;
		color: #fff;
		text-align: center;
		line-height: 24px;
		margin-right: 8px;
	}

	.supplierinfo-wrapper .si-sct3 .c .itms .itm .txt {
		font-size: 14px;
		color: #1B1D23;
		line-height: 26px;
	}

	.supplierinfo-wrapper .si-sct3 .c .now-join-btn1 {
		width: 200px;
		height: 48px;
		background: #00ad42;
		border-radius: 2px;
		text-align: center;
		line-height: 48px;
		margin: 30px auto 0;
		font-size: 16px;
		color: #fff;
	}
</style>
